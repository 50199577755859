import { Link } from "react-router-dom";
import styles from "./Home.module.css";
import { IoBeerSharp, IoBeer } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { GiSmartphone, GiBubbles } from "react-icons/gi";
import { FiClock } from "react-icons/fi";
import { FaTools } from "react-icons/fa";

function HomePage() {
  return (
    <>
      <section className={styles.section}>
        <h2>Your friendly neighborhood Beer Guy!</h2>
        <div className={styles.flex}>
          <div className={styles.half}>
            <p>
              The Beer Guy is proud to offer draft beer equipment, repair, and
              services to Edmonton, Alberta, and to all of western Canada! With
              over 12 years of experience in the bar & draft beer maintenance
              industry and related trades, we are committed to reducing costs
              and maximizing the profitability of draft beer systems. We do this
              by optimizing beer systems to minimize waste, both during cleaning
              and daily operation of the system. So why do you need a Beer Guy
              and what seperates our service from others? Well, we offer a Beer
              Guy Guarantee! What this means for you is:
            </p>
            <ul>
              <li>
                Professionalism and customer service will always be first
                priority! Always happy to help with a smile, you'll receive
                honest and knowledgeful service 100% of the time. No hidden
                bills or unprofessional demeanor here.
              </li>
              <li>
                We offer the most reliable, effective, and affordable draft beer
                line cleaning, service, and installations in Alberta.
              </li>
              <li>
                You have our commitment to save you money & maximize profits,
                fix any issues that arise, educate you on your system and best
                practices, and to extend the life of your system overall.
              </li>
            </ul>
            <p>
              By choosing The Beer Guy Draft Services, you are choosing to have
              a reliable technician on hand with experience servicing 100+
              monthly clients, professional and certified training on
              installations and service, and the friendliness and willingness to
              help you with any situation at the lowest cost possible. There is
              no question, if you're looking for the best, you've found it!
            </p>
            <Link to="/about" className={styles.linkbutton}>
              Learn more about us!
            </Link>
          </div>
          <div className={styles.aboutpic}></div>
        </div>
      </section>

      <section className={styles.darksection}>
        <h2>Our Services</h2>
        <div className={styles.flex}>
          <div className={`${styles.servicetab} ${styles.gold}`}>
            <h3>
              Cleaning <GiBubbles />
            </h3>
            <p>
              The Beer Guy is proud to offer the best cleaning service available
              in western Canada! Equipped to clean any type of line and system,
              we are able to cover your beer, wine, cocktail, and nitro coffee
              line cleaning needs. With a commitment to detail in every clean,
              we strive to give every client a high level of service, knowledge,
              and satisfaction. What makes us stand out from the competition?
              Our Beer Guy guarantee means we don't cut corners and includes:
            </p>
            <ul>
              <li>Faucet scrubs with EVERY clean</li>
              <li>Line flush with top of the line cleaner</li>
              <li>
                A general diagnosis of your systems health with EVERY clean
              </li>
            </ul>
            <p>
              With our monthly subscription services available to the Edmonton
              area monthly and all rural locations Bi-monthly, we aim to provide
              our clients with the cleaning standards outlined in the The
              Brewers Association guide for cleanliness.
            </p>
          </div>
          <div className={`${styles.servicetab} ${styles.pale}`}>
            <h3>
              Installations <IoBeer />
            </h3>
            <p>
              Are you looking to put a system in your home or buisness? If it
              comes out of a tap, we install it! Beer may be the name of the
              business, but cocktails, wine, and nitro coffee are increasing in
              popularity by the day! Being a micromatic certified installer, no
              installation is too big or too small for us. We love working with
              owners to come up with unique solutions for their unique spaces
              and needs. Our connection with multiple high-end suppliers means
              we can connect you with unique custom designs on towers and
              equipment, so you can have a system that stands out, no cookie
              cutter systems! Being a fresh small buisness comes with many
              benefits when it comes to installations. It gives us pricing
              flexiblity so our clients can pay less for more, schedule
              flexibilty so our clients installation will always be priority
              first, and it comes with a drive and motivation to give our
              clients the best possible system so everyone involved can be proud
              and want to show it off!
            </p>
          </div>
          <div className={`${styles.servicetab} ${styles.brown}`}>
            <h3>
              Service <FaTools />
            </h3>
            <p>
              Draft beer can provide the best profit margins for bars &
              restaurants, what makes or breaks this is a healthy pour. The Beer
              Guy is equipped to handle a variety of mechanical repairs, and the
              knowledge to diagnose a variety of issues that can be holding back
              your draft beer from its best pour.
            </p>
            <ul>
              <li>Beer leaks</li>
              <li>Gas leaks</li>
              <li>Excessive foam</li>
              <li>Glycol decks</li>
              <li>Faucets, couplers, regulators, and more</li>
            </ul>
            <p>
              Ready to handle any issue, with a guarantee that if I can't help
              you, you won't be stuck with any outrageous fee. You can rely on
              your Beer Guy!
            </p>
          </div>
        </div>
        <Link to="/services" className={styles.linkbutton}>
          Learn more about our services!
        </Link>
      </section>

      <section className={styles.darksection}>
        <h3>Why Choose to tap it?</h3>
        <p>
          When draft lines are properly installed and maintained, few would
          disagree that beer on tap is better than bottles and cans. Those
          drinking draft beer have all their senses engaged, it offers a crisper
          taste, a better body, a richer scent, and more visual appeal; and for
          the average bar owner, draft beer offers higher profit margins. A
          recent study by Future Market Insights reveals that keg sales are
          rising and projected to continue growing. So while draft beer is still
          the leading kegged beverage, it's no longer unusual to find
          establishments offering wine, coffee, and other kegged cocktails on
          tap.
        </p>
        <ul className={`${styles.flex}`}>
          <li className={styles.info_bubble}>
            Today it really isn't an option, with microbrew and craft beers
            growing in popularity (up more than 13% last year alone), operators
            need to promote seasonal beers on tap to offer variety. Draft beer
            is also an ideal way to sample new flavor profiles, which can often
            be sold at a premium. The best way to advertise these beers? The
            handle on a highly visible tap tower, not buried within a cooler.
          </li>
          <li className={styles.info_bubble}>
            Today's customers don't want a show when thier cocktail is being
            made, they want a drink poured quickly and consistently, and that's
            just what kegged cocktails offer. Pre-batched cocktails on tap allow
            bartenders to serve drinks in a fraction of the time with a
            consistent taste no matter who's working the bar. When there are
            improvements to volume, speed of service, and quality, profits are
            sure to improve too.
          </li>
          <li className={styles.info_bubble}>
            Not only does wine on tap taste good, but it also maintains that
            taste for a while. Over time, sometimes just a matter of days,
            opened bottles eventually shift from their original flavor.
            Sparkling wines become flat; white, sweet, and rose wines oxidize
            and lose character, and red wines begin to sour and taste
            “vinegary.” This is a turnoff to guests and can result in a lot of
            waste. With kegged wine, bar and restaurant owners don't need to pay
            for the winery's cost in bottles, corks, and labeling, saving money
            with each pour.
          </li>
          <li className={styles.info_bubble}>
            Cold brew and nitro cold brew coffees are seeing exponential growth
            year over year and are perfect for almost any establishment.
            Restaurants, coffee shops, and convenience stores, typically serving
            from a small kegerator, offers convenience and freshness at just the
            right temperature. And, with a nitro infusion, you can also add some
            wow factor to each pour.
          </li>
        </ul>
        <p>
          If there's any more reason you need to have a draft system in your
          establishment, think environmental sustainability and responsibility.
          Kegs are the most environmentally-responsible beverage package in the
          brewing industry; they're returnable, refillable, and recyclable and
          have an incredible 30-year life span, serving approximately 27,000
          glasses before they are retired. That equals 1,125 cases of bottles or
          cans! Now multiply that by the number of kegs in a brewery, in a city,
          in a province/state, in the country, and worldwide; there is no
          question that the environmental impact is massive.
        </p>
      </section>

      <section className={styles.section}>
        <h2>
          Contact Us <IoBeerSharp className="icon" />
        </h2>
        <div className={styles.flex}>
          <div className={styles.contactpic}></div>
          <div className={styles.contacthalf}>
            <h3>
              Buisness Hours <FiClock className="icon" />
            </h3>
            <ul>
              <li>Monday - Friday</li>
              <li>8pm - 4pm</li>
            </ul>
            <br />
            <ul>
              <li>Call or Text</li>
              <li>
                <GiSmartphone className="icon" /> (780) 340-8567
              </li>
              <li>
                <HiOutlineMail className="icon" />
                Justin@TheBeerGuyDS.com
              </li>
              <li>
                <br />
                Office
              </li>
              <li>
                <HiOutlineMail className="icon" />
                Madison@TheBeerGuyDS.com
              </li>
            </ul>
            <p>
              Are you a Brewer or Beer Representative? Get hold of us for
              special rates!
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePage;
