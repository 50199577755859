import styles from "./ClientFooter.module.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function ClientFooter() {
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <section className={styles.logos}>
      <OwlCarousel className={`${styles.logolist} owl-theme`} {...options}>
        <a
          className="item"
          href="https://bostonpizza.com/"
          target="_blank"
          rel="noreferrer"
        >
          <li className={`${styles.logopic1} ${styles.logopic}`}></li>
        </a>
        <a
          className="item"
          href="https://mrmikes.ca/"
          target="_blank"
          rel="noreferrer"
        >
          <li className={`${styles.logopic2} ${styles.logopic}`}></li>
        </a>
        <a
          className="item"
          href="https://whyte.thepint.ca/"
          target="_blank"
          rel="noreferrer"
        >
          <li className={`${styles.logopic3} ${styles.logopic}`}></li>
        </a>
      </OwlCarousel>
      <h2>Our Clients</h2>
    </section>
  );
}

export default ClientFooter;
