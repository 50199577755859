import { useState } from "react";
import styles from "./Contact.module.css";
import FAQ from "../components/Faq";
import { IoBeerSharp } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { GiSmartphone } from "react-icons/gi";
import { FiClock } from "react-icons/fi";

function ContactPage() {
  const [faqs, setFaqs] = useState([
    {
      question: "What services do you offer?",
      answer: "Cleaning, repair, install, and educate on all draft systems!",
      open: false,
    },
    {
      question: "Why is my beer warm?",
      answer:
        "If you're using a direct draw beer system, check your refridgeration is running properly. If your system is a long draw, this can be a range of issues involved with your glycol unit (pump/motor, compressor, old glycol etc). You're best option is to call a professional technician.",
      open: false,
    },
    {
      question: "Why is my beer pouring wild? (excessively foamy)",
      answer:
        "A very common occurance with draft beer systems. Improper gas pressure or temperature at the tap, kinks in the beer line, and irregular cleaning schedules are all very common causes.",
      open: false,
    },
    {
      question: "Why is my beer cloudy?",
      answer:
        "This can be common when your beer temperature is excessively cold or if you're drawing gas pressure from a contaminated source such as an air compressor.",
      open: false,
    },
    {
      question: "Why is my beer flat?",
      answer:
        "Many factors can contribute to a flat pour. Improper gas pressure can lead to improper carbonation, dirty glassware with grease on the inside, or expired kegs that have been tapped too long can all lead to flat beer.",
      open: false,
    },
    {
      question: "Why does my beer taste off?",
      answer:
        "Not having your beer lines frequently cleaned can lead to an off taste. Dirty glassware and kegs that are expired or aren't kept to proper storage standard can also cause improper or sour tastes.",
      open: false,
    },
    {
      question: "How often should I be cleaning my beer lines?",
      answer:
        "Brewers Association recommends commercial beer lines be cleaned every 2 weeks, it is common for it to be done monthly though",
      open: false,
    },
    {
      question: "Do you provide gas?",
      answer:
        "While we service gas systems involved with draft systems, we do not provide or deliver the gas itself.",
      open: false,
    },
    {
      question: "How often do you service my rural area?",
      answer:
        "Here at The Beer Guy we try to reach all rural areas needing cleaning and service atleast every 2 months.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <>
      <section className={styles.darksection}>
        <h2>Frequently Asked Questions</h2>
        <div className={`${styles.faqs}`}>
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </section>

      <section className={styles.section}>
        <h2>
          Contact Us <IoBeerSharp className="icon" />
        </h2>
        <div className={styles.flex}>
          <div className={styles.contactpic}></div>
          <div className={styles.contacthalf}>
            <h3>
              Buisness Hours <FiClock className="icon" />
            </h3>
            <ul>
              <li>Monday - Friday</li>
              <li>8pm - 4pm</li>
            </ul>
            <br />
            <ul>
              <li>Call or Text</li>
              <li>
                <GiSmartphone /> (780) 340-8567
              </li>
              <li>
                <HiOutlineMail />
                Justin@TheBeerGuyDS.com
              </li>
              <li>
                <br />
                Office
              </li>
              <li>
                <HiOutlineMail />
                Madison@TheBeerGuyDS.com
              </li>
            </ul>
            <p>
              Are you a Brewer or Beer Representative? Get a hold of us for
              special rates!
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactPage;
