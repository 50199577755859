import styles from "./Services.module.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";

function ServicesPage() {
  return (
    <>
      <h2>Our Services</h2>
      <section className={styles.section}>
        <h3>Cleaning</h3>
        <p>
          Did you know that beer and technical experts all agree that beer line
          cleanings should be done every two weeks? It is also becoming more and
          more common for regular line cleanings to be mandated by law in some
          states and provinces! (Ontario it is now required every 45days). Since
          dirty or infected lines can lead to a great deal of wasted beer,
          bacteria and filth can actually constrict the flow of beer, causing
          excessive foaming at the tap, owners have a financial incentive to
          keep their lines clean. At The Beer Guy, we wanna make the experience
          of regular cleanings more benficial to buisness owners.
        </p>
        <div className={styles.flex}>
          <div className={styles.half}>
            <table>
              <tr>
                <th>Prices</th>
                <th>Edmonton Area</th>
                <th>Rural (More than 50km outside of Edmonton)</th>
              </tr>
              <tr>
                <td>First 2 lines</td>
                <td>$50</td>
                <td>$70</td>
              </tr>
              <tr>
                <td>Each line after</td>
                <td>$12</td>
                <td>$14</td>
              </tr>
              <tr>
                <td>
                  <b>Acid Clean</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>First 2 lines</td>
                <td>$75</td>
                <td>$95</td>
              </tr>
              <tr>
                <td>Each line after</td>
                <td>$14</td>
                <td>$16</td>
              </tr>
              <tr>
                <td>
                  <b>Recirculation Clean</b>
                </td>
                <td>Service rates apply</td>
                <td>Service rates apply</td>
              </tr>
              <tr>
                <td>
                  <b>Travel Rate</b>
                </td>
                <td>N/A</td>
                <td>$0.25/KM</td>
              </tr>
              <tr></tr>
            </table>
          </div>
          <div className={styles.half}>
            <p>
              The Beer Guy is proud to offer the best cleaning service available
              in western Canada! But, what do we do exactly? Using alkaline
              cleaners, applied every cleaning, we attack and dissolve organic
              proteins, carbohydrates, hop resins, and biofilms; it is also very
              effective in killing mold, bacteria, and yeast. Acid line
              cleaners, applied every three to four months, dissolve inorganic
              mineral-type deposits, commonly referred to as beer stone. We're
              equipped to clean any type of line and system: beer, wine,
              cocktail, and nitro coffee, meaning we can handle ALL your line
              cleaning needs. With a commitment to detail in every clean, we
              strive to give every client a high level of service, knowledge,
              and satisfaction. What makes us stand out from the competition?
              Our Beer Guy guarantee means we don't cut corners and includes:
            </p>
            <ul>
              <li>Faucet scrubs with EVERY clean</li>
              <li>Line flush with top of the line cleaner</li>
              <li>
                A general diagnosis of your systems health with EVERY clean,
                preventing issues before they cost you!
              </li>
            </ul>
          </div>
          <div className={styles.half}>
            <p>
              All these services will come with our regular line cleaning, but
              we are also happy to introduce our Beer Guy subscription service!
              With this service we aim to provide our clients with the cleaning
              standards outlined in the The Brewers Association guide for
              cleanliness. More details on can be viewed{" "}
              <a
                href="https://www.micromatic.com/en-us/learn/dispensing-knowledge/learning-resource-center/importance-of-beer-line-cleaning?fbclid=IwAR2Pz3ruX6AQcPfhM8iOss1E9ux1R2Sth_4UMbCtfDXbL3rIpZEX40EXa3w"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              with a great articule from MicroMatic on the cleaning standards to
              keep your beer system in perfect health. To summarize though, all
              clients subscribed to be cleaned to us monthly will also recieve:
            </p>
            <ul>
              <li>
                Equipment cleanings (couplers, fobs, exterior lines inside the
                cooler)
              </li>
              <li>
                A free additional acid flush every 4th clean to deep clean the
                lines
              </li>
              <li>
                We will use water to flush out long draw systems. This leaves
                your system in a better state to pour, saves your beer from the
                drain, and makes cleanings more financially beneficial
              </li>
              <li>Rural customers won't have to pay additional travel fees</li>
            </ul>
          </div>
          <div className={styles.half}>
            <table>
              <tr>
                <th></th>
                <th>Regular Cleanings</th>
                <th>Subscription Cleanings</th>
              </tr>
              <tr>
                <td>Faucet Scrub</td>
                <td>
                  <BsCheckCircleFill />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
              <tr>
                <td>Line flush with top of the line cleaner</td>
                <td>
                  <BsCheckCircleFill />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
              <tr>
                <td>A system health report</td>
                <td>
                  <BsCheckCircleFill />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
              <tr>
                <td>Acid clean every 4th cleaning</td>
                <td>
                  <IoMdCloseCircle size={"1.2rem"} />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
              <tr>
                <td>Equipment Cleaning</td>
                <td>
                  <IoMdCloseCircle size={"1.2rem"} />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
              <tr>
                <td>No travel fees</td>
                <td>
                  <IoMdCloseCircle size={"1.2rem"} />
                </td>
                <td>
                  <BsCheckCircleFill />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <p>
          Monthly subscription services are available to the Edmonton area
          monthly and all rural locations Bi-monthly. Subscription prices, while
          comparable to our regular cleanings, are subject to site inspection
          for various reasons such as: size & state of system, access to water,
          location, and access to glycol deck. Recirculation cleanings are not
          compatible with all systems and may not be available to you.
          Appointment no shows or last minute cancellations are subject to a $40
          cancel fee.
        </p>
        <p>
          <i>
            *Referral program available! Spread the word to save yourself
            money!*
          </i>
        </p>
      </section>

      <section className={styles.darksection}>
        <h3>Service</h3>
        <div className={styles.flex}>
          <div className={styles.half}>
            <p>
              Draft beer can provide the best profit margins for bars &
              restaurants, what makes or breaks this is a healthy pour. The Beer
              Guy is equipped to handle a variety of mechanical repairs, and the
              knowledge to diagnose a variety of issues that can be holding back
              your draft beer from its best pour.
            </p>
            <ul>
              <li>Beer leaks</li>
              <li>Gas leaks</li>
              <li>Excessive foam</li>
              <li>Glycol decks</li>
              <li>Faucets, couplers, regulators, and more</li>
            </ul>
            <p>
              Ready to handle any issue, with a guarantee that if I can't help
              you, you won't be stuck with any outrageous fee. You can rely on
              your Beer Guy!
            </p>
          </div>
          <div className={styles.half}>
            <table>
              <tr>
                <th></th>
                <th>Minimum</th>
                <th>Edmonton Area</th>
                <th>Rural (More than 50km outside of Edmonton)</th>
              </tr>
              <tr>
                <td>
                  <b>Service Rate</b>
                </td>
                <td>$40</td>
                <td>$80/hr</td>
                <td>$100/hr</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Off Buisness Hours</b>
                </td>
                <td>$60</td>
                <td>$120/hr</td>
                <td>$150/hr</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>In-Shop Service Rate</b>
                </td>
                <td>$30</td>
                <td>$60/hr</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Travel Rate</b>
                </td>
                <td></td>
                <td>N/A</td>
                <td>$0.25/KM</td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <h3>Installations</h3>
        <div className={styles.flex}>
          <div className={styles.half}>
            <p>
              Are you looking to put a system in your home or buisness? If it
              comes out of a tap, we install it! Beer may be the name of the
              business, but cocktails, wine, and nitro coffee are increasing in
              popularity by the day! Being a micromatic certified installer, no
              installation is too big or too small for us. We love working with
              owners to come up with unique solutions for their unique spaces
              and needs. Our connection with multiple high-end suppliers means
              we can connect you with unique custom designs on towers and
              equipment, so you can have a system that stands out, no cookie
              cutter systems! Being a fresh small buisness comes with many
              benefits when it comes to installations. It gives us pricing
              flexiblity so our clients can pay less for more, schedule
              flexibilty so our clients installation will always be priority
              first, and it comes with a drive and motivation to give our
              clients the best possible system so everyone involved can be proud
              and want to show it off! Prices are subject to the systems needs,
              labor, and site inspection.
            </p>
          </div>
          <div className={`${styles.half}, ${styles.certificationpic}`}></div>
        </div>
        <div>
          <h3>Why Choose to tap it?</h3>
          <p>
            When draft lines are properly installed and maintained, few would
            disagree that beer on tap is better than bottles and cans. Those
            drinking draft beer have all their senses engaged, it offers a
            crisper taste, a better body, a richer scent, and more visual
            appeal; and for the average bar owner, draft beer offers higher
            profit margins. A recent study by Future Market Insights reveals
            that keg sales are rising and projected to continue growing. So
            while draft beer is still the leading kegged beverage, it's no
            longer unusual to find establishments offering wine, coffee, and
            other kegged cocktails on tap.
          </p>
          <ul className={`${styles.flex}`}>
            <li className={styles.info_bubble}>
              Today it really isn't an option, with microbrew and craft beers
              growing in popularity (up more than 13% last year alone),
              operators need to promote seasonal beers on tap to offer variety.
              Draft beer is also an ideal way to sample new flavor profiles,
              which can often be sold at a premium. The best way to advertise
              these beers? The handle on a highly visible tap tower, not buried
              within a cooler.
            </li>
            <li className={styles.info_bubble}>
              Today's customers don't want a show when thier cocktail is being
              made, they want a drink poured quickly and consistently, and
              that's just what kegged cocktails offer. Pre-batched cocktails on
              tap allow bartenders to serve drinks in a fraction of the time
              with a consistent taste no matter who's working the bar. When
              there are improvements to volume, speed of service, and quality,
              profits are sure to improve too.
            </li>
            <li className={styles.info_bubble}>
              Not only does wine on tap taste good, but it also maintains that
              taste for a while. Over time, sometimes just a matter of days,
              opened bottles eventually shift from their original flavor.
              Sparkling wines become flat; white, sweet, and rose wines oxidize
              and lose character, and red wines begin to sour and taste
              “vinegary.” This is a turnoff to guests and can result in a lot of
              waste. With kegged wine, bar and restaurant owners don't need to
              pay for the winery's cost in bottles, corks, and labeling, saving
              money with each pour.
            </li>
            <li className={styles.info_bubble}>
              Cold brew and nitro cold brew coffees are seeing exponential
              growth year over year and are perfect for almost any
              establishment. Restaurants, coffee shops, and convenience stores,
              typically serving from a small kegerator, offers convenience and
              freshness at just the right temperature. And, with a nitro
              infusion, you can also add some wow factor to each pour.
            </li>
          </ul>
          <p>
            If there's any more reason you need to have a draft system in your
            establishment, think environmental sustainability and
            responsibility. Kegs are the most environmentally-responsible
            beverage package in the brewing industry; they're returnable,
            refillable, and recyclable and have an incredible 30-year life span,
            serving approximately 27,000 glasses before they are retired. That
            equals 1,125 cases of bottles or cans! Now multiply that by the
            number of kegs in a brewery, in a city, in a province/state, in the
            country, and worldwide; there is no question that the environmental
            impact is massive.
          </p>
        </div>
      </section>
    </>
  );
}

export default ServicesPage;
