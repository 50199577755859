import logoImage from "../assets/logo.png";
import styles from "./Header.module.css";
import MainNavigation from "../components/MainNavigation";

const Header = (props) => {
  return (
    <header className={styles.header}>
      <MainNavigation />
      <div className={styles.flex}>
        <div className={styles["logo-image"]}>
          <img src={logoImage} alt="The Beer Guy logo" />
          <h1>The Beer Guy</h1>
        </div>
        <div className={styles.flexcolumn}>
          <h3>No need to fear, your Beer Guy is here!</h3>
          <span>Call or Text</span>
          <span>(780)340-8567</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
